<template>
  <div id="app" style="height: 100vh; overflow: auto;">

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

    <div>
      <!-- 任务明细-->
      <div class="white_box" style="margin-top: 16px; padding-top: 12px;">
        <div style="display: flex">
          <div style="flex: 5; font-size: 14px; color: #222222; font-weight: bold; line-height: 14px;">
            {{ this.data.taskReservationConfirmUser.taskReservationConfirm.name }}
          </div>
          <div :class="handleStateClass(data.taskReservationConfirmUser.confirmState)"
               style="flex: 2; text-align: end;">
            {{ handleConfirmState(data.taskReservationConfirmUser.confirmState) }}
          </div>
        </div>

        <div style="display: flex; color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
          {{ '待确认人: ' }}
          <div style="margin-left: 4px;" v-if="this.data.taskReservationConfirmUser.businessCustomerUser.name">
            {{ this.data.taskReservationConfirmUser.businessCustomerUser.name }}
          </div>
        </div>

        <div style="display: flex; color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
          {{ '开始时间: ' }}
          <div style="margin-left: 4px;" v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.startTime">
            {{ this.data.taskReservationConfirmUser.taskReservationConfirm.startTime | moment('yyyy-MM-DD HH:mm:ss') }}
          </div>
        </div>

        <div style="display: flex; color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
          {{ '截止时间: ' }}
          <div style="margin-left: 4px;" v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.endTime">
            {{ this.data.taskReservationConfirmUser.taskReservationConfirm.endTime | moment('yyyy-MM-DD HH:mm:ss') }}
          </div>
        </div>

        <div style="display: flex; color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
          {{ '确认范围: ' }}
          <div style="margin-left: 4px;"
               v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.reservationStartTime">{{
              this.data.taskReservationConfirmUser.taskReservationConfirm.reservationStartTime | moment('yyyy-MM-DD')
            }}
          </div>
          <div style="margin-left: 4px;"
               v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.reservationStartTime">{{ ' 至 ' }}
          </div>
          <div style="margin-left: 4px;"
               v-if="this.data.taskReservationConfirmUser.taskReservationConfirm.reservationEndTime">
            {{ this.data.taskReservationConfirmUser.taskReservationConfirm.reservationEndTime | moment('yyyy-MM-DD') }}
          </div>
        </div>

      </div>

      <!-- 用餐明细-->
      <div class="white_box" style="margin-top: 12px; padding-top: 12px;">

        <div style="font-size: 14px; color: #222222; font-weight: bold; margin-bottom: 12px;">用餐明细</div>

        <div v-for="(productBundle, i) in productBundleList" :key="i">

          <div style="margin-top: 16px" v-if="i != 0"></div>
          <div
              style="font-size: 14px; line-height: 14px; color: #222222; font-weight: bold; margin-bottom: 12px; border-left: 3px #34AC40 solid; padding-left: 6px;">
            {{ productBundle.name }}
          </div>
          <div style="display: flex; margin-bottom: 8px; background-color: #F2F2F2; color: #222222; padding: 2px 0;">
            <div style="flex: 1; text-align: center; align-self: center;">日期</div>
            <!--            <div style="flex: 1; text-align: center;">类型</div>-->
            <van-dropdown-menu active-color="#34AC40" style="flex: 1;">
              <van-dropdown-item v-model="value1" :options="option1" @change="change"
                                 @opened="opened(productBundle.id)"/>
            </van-dropdown-menu>

            <div style="flex: 1; text-align: center; align-self: center;">状态</div>
          </div>

          <div v-for="(item, index) in productBundleMap.get(productBundle.id)" :key="index">
            <div v-if="item.show === 1">
              <div style="display: flex; margin-top: 8px;">
                <div style="flex: 1; text-align: center; align-self: center; font-size: 14px; line-height: 14px;">
                  {{ item.day }}
                </div>
                <div
                    :class="item.type === 'ENROLL' ? 'reservation_record_type_enroll' : 'reservation_record_type_stop'">
                  {{
                    handleReservationType(item.type)
                  }}
                </div>
                <div
                    :class="handleStateClass(item.confirmState !== 'UNCONFIRMED' ? item.state : 'UNCONFIRMED')">
                  {{
                    handleDetailState(item.state, item.confirmState)
                  }}
                </div>
              </div>
              <div v-if="index < data.reservationRecordCutList.length - 1"
                   style="background-color: #f1f1f1; height: 1px; margin-top: 8px"></div>
            </div>
          </div>
        </div>

        <van-empty class="custom-image"
                   v-if="!productBundleList || productBundleList.length===0"
                   description="无报停餐记录"/>

      </div>

      <!-- 异议明细-->
      <div class="white_box" style="margin-top: 12px; padding-top: 12px; margin-bottom: 80px;">

        <div style="font-size: 14px; color: #222222; font-weight: bold; margin-bottom: 12px;">异议记录</div>

        <div v-for="(item, index) in data.taskReservationDissentCutList" :key="index">
          <div style="margin-top: 6px; font-size: 14px; line-height: 14px;">{{ item.dissentRemark }}</div>
          <div style="display: flex; margin-top: 8px; font-size: 12px; line-height: 12px; color: #A1A1A1;">
            <label>{{ handleDissentState(item.dissentState) }}</label>
            <div style="margin-left: 4px;">
              {{ item.createdTime | moment('yyyy-MM-DD HH:mm:ss') }}
            </div>
          </div>

          <div v-if="item.dissentAttachmentUrl" style="margin-top: 8px;">
            <van-image
                width="24vw"
                height="24vw"
                :src="item.dissentAttachmentUrl"/>
          </div>

          <div v-if="item.dissentState !== 'PENDING'"
               style="background-color: #f1f1f1; border-radius: 4px; padding: 8px; margin-top: 12px">
            <div style="color: #222222; line-height: 14px;">客服答复</div>
            <div style="line-height: 14px; margin-top: 8px;">
              {{ item.dissentResponse }}
            </div>
            <div style="font-size: 12px; line-height: 12px; margin-top: 4px; color: #A1A1A1;">
              {{ item.updatedTime | moment('yyyy-MM-DD HH:mm:ss') }}
            </div>
          </div>

          <div v-if="index < data.taskReservationDissentCutList.length - 1"
               style="background-color: #f1f1f1; height: 1px; margin-top: 12px">
          </div>
        </div>

        <van-empty class="custom-image"
                   v-if="!data.taskReservationDissentCutList || data.taskReservationDissentCutList.length===0"
                   description="无历史异议记录"/>

      </div>

    </div>

    <!-- 按钮 -->
    <div class="white_box3 mgt_3vw" v-if="showBtn()"
         style="position: absolute; bottom: 0px; width: 100%; padding-bottom: 12px;">
      <div class="white_box3_in pdb_0">
        <div class="tree_btn2">
          <ul class="clear">
            <li style="width: 50%; padding: 0 8px;">
              <button class="btn_confirm_line" @click="onClickDissent">异议</button>
            </li>
            <li style="width: 50%; padding: 0 8px;">
              <button class="btn_confirm" @click="onClickConfirm">确认</button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <van-dialog v-model="showResult" confirm-button-color="#222222" @confirm="onResultConfirm">
      <div class="feedback_in" style="padding: 24px 0 24px 0">
        <div style="text-align: -webkit-center;">
          <div style="height: 100px; width: 100px;" :style="{backgroundImage:`url(${resultIconUrl})`}"/>
        </div>
        <div style="color: #222222; margin-top: 4px;">{{ resultState }}</div>
        <div style="color: #222222; margin-top: 4px;">{{ resultTitle }}</div>
        <div v-if="resultCreatedBy !== ''" style="color: #A1A1A1; font-size: 14px; line-height: 14px; margin-top: 8px">
          {{
            '提交人: ' + resultCreatedBy
          }}
        </div>
        <div style="color: #A1A1A1; font-size: 14px; line-height: 14px; margin-top: 8px">{{
            '提交时间: ' + resultCreatedTime
          }}
        </div>
      </div>
    </van-dialog>

  </div>
</template>

<script>
import moment from "moment";
import {post} from "../utils/http";
import Vue from "vue";
import {ACCESS_CustomerUser} from "../store/mutation-types";
import {Dialog, Toast} from "vant";

export default {
  name: "reservation_record_confirm",
  data() {
    return {
      id: null,
      userId: null,
      loading: false,
      data: {
        taskReservationConfirmUser: {
          businessCustomerUser: {},
          taskReservationConfirm: {}
        },
        taskReservationConfirmUserId: null,
        reservationRecordCutList: [],
        taskReservationDissentCutList: [],
      },
      productBundleList: [],
      reservationRecordData: [{}, {}, {}],
      dissentData: [{}, {}],
      showResult: false,
      resultIconUrl: '../assets/images/ico_success2.svg',
      resultState: '',
      resultTitle: '',
      resultCreatedBy: '',
      resultCreatedTime: '',

      productBundleMap: new Map(),

      openedProductBundleId: '',
      value1: 0,
      option1: [
        {text: '全部', value: 0},
        {text: '已用餐', value: 1},
        {text: '已停餐', value: 2},
      ]
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
    this.currentCustomerUser = Vue.ls.get(ACCESS_CustomerUser);
    this.id = this.$route.query.id;
    this.userId = this.$route.query.userId;

    this.fetchData();
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      if (this.data.taskReservationConfirmUser.confirmState !== 'UNCONFIRMED') {
        this.backToList();
        return;
      }
      Dialog.alert({
        message: '请按时确认，超时视为无异议自动确认哦！'
      }).then(() => {
        this.backToList();
      })
    },
    backToList() {
      // 提交确认请求
      this.$router.push('/reservationRecordConfirmList');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    fetchData() {
      this.loading = true;
      post('api/business/v2/taskReservationConfirm/detail/read', {
        taskReservationConfirmUserId: this.userId,
        taskReservationConfirmId: this.id
      }).then(res => {
        this.loading = false;
        if (res.data.taskReservationConfirmUser.taskReservationConfirm.state === 'NOT_STARTED') {
          Dialog.alert({message: '未开始哦，请开始后再来确认~'}).then(() => {
            this.backToList();
          });
          return;
        }

        this.data = res.data;

        // 处理停餐数据
        const map = new Map();
        let productBundleList = []
        this.data.reservationRecordCutList.forEach(item => {
          productBundleList.push({
            id: item.productBundleId,
            name: item.productBundleName
          });
        });

        this.productBundleList = productBundleList.filter(item => !map.has(item.id) && map.set(item.id, 1));

        const productBundleMap = new Map();
        this.productBundleList.forEach(item => {
          const reservationRecordList = [];
          this.data.reservationRecordCutList.forEach(reservationRecordCut => {
            if (reservationRecordCut.productBundleId === item.id) {
              reservationRecordCut.show = 1;
              reservationRecordList.push(reservationRecordCut);
            }
          });

          productBundleMap.set(item.id, reservationRecordList);
        });

        this.productBundleMap = productBundleMap;

      }).catch(err => {
        this.loading = false;
      });
    },
    onClickConfirm() {
      Dialog.confirm({
        message: '请您再次确认无误？'
      }).then(() => {
        // 提交确认请求
        this.requestConfirm();
      }).catch(err => {
      })
    },
    onClickDissent() {
      // 跳转异议处理页面
      this.$router.push({path: '/reservationRecordConfirmDissent', query: {id: this.id, userId: this.userId}});
    },
    onResultConfirm() {

    },

    requestConfirm() {
      this.loading = true;
      post('api/business/v2/taskReservationConfirm/user/confirm', {
        taskReservationConfirmUserId: this.userId,
        taskReservationConfirmId: this.id
      }).then(res => {
        this.data.taskReservationConfirmUser.confirmState = 'CONFIRM';

        this.fetchData();
        this.loading = false;
        this.showResult = true;
        this.resultIconUrl = require('../assets/images/ico_success2.svg');
        this.resultState = '确认成功';
        this.resultTitle = '';
        this.resultCreatedBy = res.data.createdByName;
        this.resultCreatedTime = moment(res.data.confirmTime).format('YYYY-MM-DD HH:mm:ss');
      }).catch(err => {
        this.loading = false;
        this.showResult = true;
        this.resultIconUrl = require('../assets/images/ico_fail2.svg');
        this.resultState = '确认失败';
        this.resultTitle = err.message;
        this.resultCreatedBy = '';
        this.resultCreatedTime = moment(err.timestamp).format('YYYY-MM-DD HH:mm:ss');
      });
    },

    handleReservationType(type) {
      switch (type) {
        case 'STOP':
          return '已停餐';
        case 'ENROLL':
          return '已用餐';
        default:
          return '-';
      }
    },
    handleConfirmState(confirmState) {
      switch (confirmState) {
        case 'CONFIRMED':
          return '已确认';
        case 'UNCONFIRMED':
          return '待确认';
        case 'DISSENT_PENDING':
          return '异议待处理';
        case 'DISSENT_SOLVED':
          return '异议已处理';
        default:
          return '-';
      }
    },
    handleDetailState(state, confirmState) {
      if (confirmState && confirmState === 'UNCONFIRMED') {
        return '待确认'
      }
      switch (state) {
        case 'CONFIRMED':
          return '已确认';
        case 'PENDING':
          return '待处理';
        case 'SOLVED':
          return '已处理';
        case 'REJECTED':
          return '异议已拒绝';

        default:
          return '-';
      }
    },
    handleStateClass(confirmState) {
      switch (confirmState) {
        case 'CONFIRMED':
          return 'state_normal';
        case 'UNCONFIRMED':
          return 'state_normal';
        case 'DISSENT_PENDING':
          return 'state_normal_red';
        case 'DISSENT_SOLVED':
          return 'state_normal_red';

        case 'PENDING':
          return 'state_normal_red';
        case 'SOLVED':
          return 'state_normal_red';
        case 'REJECTED':
          return 'state_normal_red';
        default:
          return '';
      }
    },
    handleDissentState(dissentState) {
      switch (dissentState) {
        case 'CONFIRMED':
          return '已确认';
        case 'PENDING':
          return '待处理';
        case 'SOLVED':
          return '已处理';
        case 'REJECTED':
          return '已拒绝';
        default:
          return '-';
      }
    },
    showBtn() {
      return this.data.taskReservationConfirmUser.confirmState === 'UNCONFIRMED' ||
          this.data.taskReservationConfirmUser.confirmState === 'DISSENT_SOLVED';
    },

    change(e) {
      let dataList = this.productBundleMap.get(this.openedProductBundleId);
      if (e === 0) {
        dataList.forEach(data => {
          data.show = 1;
        });
      } else {
        let type = 'ENROLL';
        if (e === 2) {
          type = 'STOP';
        }
        dataList.forEach(data => {
          if (data.type === type) {
            data.show = 1;
          } else {
            data.show = 0;
          }
        });
      }

    },
    opened(productBundleId) {
      this.openedProductBundleId = productBundleId;
    },
  }
}
</script>

<style scoped>
.btn_confirm {
  width: 100%;
  padding: 6px 26px;
  height: 40px;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 8px auto;
  border: none;
  background: #34AC40;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
}

.btn_confirm_line {
  width: 100%;
  padding: 6px 26px;
  height: 40px;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 8px auto;
  border: none;
  color: #ff6666;
  background-color: rgba(255, 102, 102, 0.2);
  border-radius: 2px;
  font-size: 13px;
}

.state_normal {
  flex: 1;
  text-align: center;
  align-self: center;
  font-size: 14px;
  line-height: 14px;
}

.state_normal_red {
  flex: 1;
  text-align: center;
  align-self: center;
  font-size: 14px;
  line-height: 14px;
  color: #ff6666;
}

.state_normal_green {
  flex: 1;
  text-align: center;
  align-self: center;
  font-size: 14px;
  line-height: 14px;
  color: #34AC40;
}

::v-deep .van-dropdown-menu__bar {
  /* text-align: left !important; */
  height: 24px;
  box-shadow: none;
  background-color: #F2F2F2;
  color: #222222;
}

::v-deep .van-dropdown-menu__title {
  font-size: 14px;
}

</style>